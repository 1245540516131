const header = document.querySelector('[data-cy="header"]')
const bgInitial = 'bg-transparent'
const bgScrolling = 'bg-white/90'
const borderInitial = 'border-transparent'
const borderScrolling = 'border-gray-1000/5'
let isScrolling = false

function styleHeaderOnScroll() {
  const scrollTop = window.scrollY || document.documentElement.scrollTop

  // Update the header classes when scrolling
  if (isScrolling && scrollTop <= 0) {
    isScrolling = false
    header.classList.add(bgInitial)
    header.classList.add(borderInitial)
    header.classList.remove(bgScrolling)
    header.classList.remove(borderScrolling)
  } else if (!isScrolling && scrollTop > 0) {
    isScrolling = true
    header.classList.add(bgScrolling)
    header.classList.add(borderScrolling)
    header.classList.remove(bgInitial)
    header.classList.remove(borderInitial)
  }
}

// Add the scroll event listener to the window
window.addEventListener('scroll', styleHeaderOnScroll)

// Trigger the header style in case the window is refreshed
styleHeaderOnScroll()
